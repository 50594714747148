import { AxiosRequestConfig } from 'axios';
import { handleError } from '@utils/misc';
import useCognito from '@as_core/account/useCognito';
import { authCoreClient } from '@utils/api/base';

export type UserRegistrationDataT = {
  email: string;
  name: {
    first: string;
    last: string;
    middle: string | null;
  };
  address: {
    address: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    billing: boolean;
  };
  organization: {
    type: string;
    name: string;
  };
  system: string;
  cognito?: string;
  authId?: string;
  created: string;
  _id: string;
}

export const UserRegistration = {
  get: (token: string) =>
    authCoreClient(token).get('/user'),
  create: (token: string, data: AxiosRequestConfig) =>
    authCoreClient(token).post('/user/create', data),
  update: (token: string, authId: string, data: AxiosRequestConfig) =>
    authCoreClient(token).patch(`/user/update/${authId}`, data),
  delete: (token: string, authId: string, data: AxiosRequestConfig) =>
    authCoreClient(token).delete(`/user/delete/${authId}`, data),
};

const debug: boolean = process.env.REACT_APP_DEBUG === 'dev';
const useUserRegistrationInfo = () => {
  const { getToken } = useCognito();
  const createUserRegistrationInfo = async (userInfo: {
    [key: string]: string;
  }): Promise<UserRegistrationDataT> => {
    const config: AxiosRequestConfig = userInfo;
    let resp = null;

    try {
      resp = await UserRegistration.create(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  // as this is used by AuthWrapper -- need to explicitly send token
  const getUserRegistrationInfo = async (token: string): Promise<UserRegistrationDataT> => {
    let response = null;
    try {
      response = await UserRegistration.get(token);
    } catch (error) {
      console.error('getUserRegistrationInfo Error:', error);
      throw error;
    }
    if (debug)
      console.log('getUserRegistrationInfo | response', response);
    if (response.status !== 200) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    return response.data
  };

  const updateUserRegistrationInfo = async (
  // eslint-disable-next-line
    authId: string, userinfo: any
  ): Promise<UserRegistrationDataT> => {
    const config: AxiosRequestConfig = userinfo;
    let resp;

    try {
      resp = await UserRegistration.update(getToken(), authId, config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  return {
    getUserRegistrationInfo,
    createUserRegistrationInfo,
    updateUserRegistrationInfo
  };
};
export default useUserRegistrationInfo;
