import React from 'react';
import CenteredMessages, { MessageItem } from '@as_core/elements/CenteredMessages';
import PageContainer from '@as_core/pages/PageContainer';

const NotAuthorizedPage = () => {
  return (
    <PageContainer title={'Not Authorized'}>
      <CenteredMessages
        messages={[
          <MessageItem color={'accent'}>
            Not Authorized to Access this Tool/Functionality
          </MessageItem>,
          <MessageItem>
            If you should be authorized, please submit a bug report.
          </MessageItem>,
        ]}
      />
    </PageContainer>
  )
}

export default NotAuthorizedPage;
