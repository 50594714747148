import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';

export type BasicTableFieldsT = {
  value: string;
  label: string | ReactNode;
  type?: string; // used in getTableRows functions
  align?: string;
  width?: number;
  info?: string;
  status?: string;
};

interface PropsI {
  fields: BasicTableFieldsT[];
  rows: Array<{ [key: string]: string | number | ReactNode }>;
  border?: boolean;
  header?: boolean;
  pillRows?: boolean;
  onRowClick?: (i: number) => void;
  debug?: boolean;
  stickyHeader?: boolean;
}

const BasicTable = (props: PropsI) => {
  const { fields, rows, stickyHeader } = props;

  const fullWidth = fields.reduce((accumulator, f) => accumulator + f.width, 0);
  if (props.debug) console.log('BasicTable | {fields, rows}', fields, rows);
  return (
    <BasicTableContents key={'basicTable'}>
      <TableContents
        key={'basicTable_contents'}
        border={props?.border ? props.border : false}
      >
        {props?.header ? (
          <HeaderRow stickyHeader={stickyHeader} key={'basicTable_header_row'}>
            {fields.map((field, cellIndex) => (
              <HeaderCell
                key={`basicTable_header_cell_${cellIndex}`}
                width={field.width}
                align={field?.align ? field.align : 'center'}
              >
                {field.label}
                {field?.info ? <HoverInfoText text={field.info} /> : null}
              </HeaderCell>
            ))}
          </HeaderRow>
        ) : null}
        {rows.length ? (
          rows.map((row, index) => (
            <BodyRow
              key={`basicTable_row_${index}`}
              onClick={
                props?.onRowClick ? () => props.onRowClick(index) : () => {}
              }
              clickable={!!props?.onRowClick}
              index={index}
            >
              {fields.map((field, cellIndex) => (
                <BodyCell
                  key={`basicTable_cell_${index}_${cellIndex}`}
                  width={field.width}
                  align={field?.align ? field.align : 'center'}
                >
                  {_.get(row, field.value, '')}
                </BodyCell>
              ))}
            </BodyRow>
          ))
        ) : (
          <BodyRow key={'basicTable_row_empty'} index={0}>
            <BodyCell width={fullWidth}>No data is available</BodyCell>
          </BodyRow>
        )}
      </TableContents>
    </BasicTableContents>
  );
};

BasicTable.defaultProps = {
  debug: false,
  header: true,
  border: false,
  pillRows: false,
};

export default BasicTable;

const BasicTableContents = styled.div`
  display: flex;
  width: max-content;
  height: max-content;
`;

export const TableContents = styled.div<{
  border?: boolean;
}>`
  font-size: ${(p) => p.theme.sizes.xsmall};

  border-radius: 8px;
  border: ${(p) => (p.border ? 3 : 0)}px solid
    ${(p) => p.theme.palette.backgroundPrimary};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
TableContents.defaultProps = {
  border: false,
};

export const HeaderRow = styled.div<{
  highlight?: boolean;
  stickyHeader?: boolean;
}>`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: flex-start;
  position: ${(p) => (p.stickyHeader ? 'sticky' : 'relative')};
  top: 0;
  z-index: 5;
  color: ${(p) =>
    p.highlight
      ? p.theme.palette.backgroundPrimary
      : p.theme.palette.textPrimary};
  border-radius: 8px;
  background-color: ${(p) =>
    p.highlight
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundQuaternary};
`;
HeaderRow.defaultProps = {
  highlight: false,
  stickyHeader: false,
};

export const HeaderCell = styled.div<{
  width: number;
  align?: string;
  leftBorder?: boolean;
}>`
  display: flex;
  justify-content: ${(p) =>
    p.align === 'left'
      ? 'flex-start'
      : p.align === 'right'
      ? 'flex-end'
      : 'center'};
  align-items: center;
  text-align: ${(p) => p.align};
  width: ${(p) => p.width}px;
  ${(p) =>
    p.leftBorder &&
    `
  border-left: 1px solid ${p.theme.palette.backgroundPrimary};
  `}
`;
HeaderCell.defaultProps = {
  align: 'center',
  leftBorder: true,
};

export const BodyRow = styled.span<{ clickable?: boolean; index: number }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
  border-radius: 8px;
  color: ${(p) => p.theme.palette.textSecondary};
  &:nth-of-type(odd) {
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
  },
  &:nth-of-type(even) {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  },
  :hover {
    cursor: ${(p) => (p.clickable ? 'pointer' : 'default')};
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
    color: ${(p) => p.theme.palette.textPrimary};
  }
}
`;

export const BodyCell = styled.div<{
  width: number;
  fontColor?: string;
  fontSize?: number;
  leftBorder?: boolean;
  align?: string;
}>`
  display: flex;
  justify-content: ${(p) =>
    p.align === 'left'
      ? 'flex-start'
      : p.align === 'right'
      ? 'flex-end'
      : 'center'};
  align-items: center;
  width: ${(p) => p.width}px;
  ${(p) =>
    p?.fontColor === 'primary' && `color: ${p.theme.palette.textPrimary}`};
  font-size: ${(p) => p.fontSize}px;
  text-align: ${(p) => p.align};
  ${(p) =>
    p.leftBorder &&
    `
    border-left: 1px solid ${p.theme.palette.backgroundPrimary};
  `}
  & :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

BodyCell.defaultProps = {
  fontSize: 12,
  leftBorder: true,
  align: 'center',
};
