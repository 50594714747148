import React, { ReactNode, memo } from 'react';
import TextButtonBasic from './TextButtonBasic';
import TextButtonToolTip, {TooltipPlacementT} from './TextButtonToolTip';

interface TextButtonPropsT {
  tooltip?: string,
  tooltipPlacement?: TooltipPlacementT,
  label: string,
  icon?: ReactNode,
  onClick?: (e) => void,
  isActive?: boolean,
  isMuted?: boolean,
  isWarning?: boolean,
  isDisabled?: boolean,
  inProcess?: boolean,
  iconRight?: boolean,
  margin?: string,
  fontSize?: number,
  width?: number,
  height?: number,
}

const TextButton = (props: TextButtonPropsT) => {
  if (props?.tooltip)
    return(
      <TextButtonToolTip {...props} />
    )
  return(
    <TextButtonBasic {...props} />
  )
}
export default memo(TextButton);
