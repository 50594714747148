import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import MenuItem from '@mui/material/MenuItem';
import InputField from '@as_core/components/inputs/InputField';
import CountryCodes from '../countryCodes.json';

const VALID_COUNTRIES: string[] = Object.values(CountryCodes);

interface propsT {
  id: string;
  label?: string;
  value: string;
  onChange: (v: string) => void;
  placeHolder?: string;
  activeUpdate?: boolean;
  width?: number;
  error?: boolean;
  handleBlur?: () => void;
}

const InputCountry = (props: propsT) => {
  const { value, onChange } = props;
  const [localValue, setLocalValue] = useState<string>(value || '');
  const [localValidation, setLocalValidation] = useState<boolean>(false); // new form have it true unless error

  let error = localValidation ? localValue === '' : props.error;
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLocalValue(e.target.value);
  }

  function handleBlur() {
    props.handleBlur();
    onChange(localValue);
  }

  // once error is thrown, then we know validation happened in upper component
  // now validate at local component
  useEffect(() => {
    if (props.error) {
      if (!localValidation) setLocalValidation(true);
    }
  }, [props.error]);

  return (
    <CountryFieldContainer>
      <InputField
        id={props.value + '_' + props?.label}
        key={props.value + '_' + props?.label}
        select
        label={props.label}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        size='small'
      >
        {VALID_COUNTRIES.map((item, index) => (
          <StyledMenuItem key={`country_${index}`} value={item}>
            {item}
          </StyledMenuItem>
        ))}
      </InputField>
    </CountryFieldContainer>
  );
};

export default InputCountry;

const CountryFieldContainer = styled.div`
  width: 100%;
`;

const StyledMenuItem = styled(MenuItem)`
  & .MuiButtonItem-root {
    font-size: 16px;
  }
`;
