import styled from 'styled-components/macro';
import _ from 'lodash';
import BugReportComments from '@subApps/bugs/components/BugReportComments';
import { BugReportT } from '@as_core/bugsReporting/bugs.types';

type UserFieldT = {
  label?: string;
  key?: string;
  type: string;
  editable?: boolean;
}

const userFields: UserFieldT[] = [
  {
    label: 'Title',
    key: 'title',
    type: 'string',
  },
  {
    label: 'Category',
    key: 'category',
    type: 'string',
  },
  {
    label: 'submitter email',
    key: 'submit.submitter.email',
    type: 'string',
  },
  {
    label: 'createdOn',
    key: 'submit.time_submitted',
    type: 'date',
  },
  {
    label: 'Description',
    key: 'description',
    type: 'string',
  },
  { type: 'line' },
  {
    label: 'Acknowledged',
    key: 'submit.acknowledged',
    type: 'boolean',
    editable: true,
  },
  {
    label: 'Date Acknowledged',
    key: 'submit.time_acknowledged',
    type: 'date',
  },
  {
    label: 'BugReference',
    key: 'bug_reference',
    type: 'string',
  },
  {
    label: 'Assigned To',
    key: 'resolution.assigned.email',
    type: 'string',
  },
  {
    label: 'Date Assigned',
    key: 'resolution.time_assigned',
    type: 'date',
  },
  { type: 'line' },
  {
    label: 'Resolved',
    key: 'resolved',
    type: 'boolean',
  },
  {
    label: 'Deferred',
    key: 'resolution.deferred',
    type: 'boolean',
  },
  {
    label: 'Resolved Date',
    key: 'resolved',
    type: 'date',
  },
  {
    label: 'Resolved Comments',
    key: 'resolution.comments',
    type: 'string',
  }
];

interface PropsI {
  report: BugReportT;
}

const debug = false;
const ViewBugReport = (props: PropsI) => {
  let { report } = props;
  debug && console.log('ViewBugReport | report:', report);

  const getValue = (report: BugReportT, item: UserFieldT) => {
    if (item?.type === 'boolean') {
      return _.get(report, item.key, '').toString();
    } else if (item?.type === 'date') {
      const value = _.get(report, item.key, '');
      if (value === '') return '';
      const utc = new Date(value);
      return utc.toLocaleDateString() + ' ' + utc.toLocaleTimeString();
    }
    return _.get(report, item.key, '');
  };

  const displayValue = (report: BugReportT, item: UserFieldT, index: number) => {
    if (item?.type === 'line') {
      return <HorizontalLine key={index} />;
    }
    return (
      <UserInformation key={index}>
        <Label>{item.label}:</Label>
        <Value>{getValue(report, item)}</Value>
      </UserInformation>
    );
  };

  return (
    <ViewContainer>
      <ReportContainer>
        {Object.keys(report) ? (
          userFields.map((item, index) => displayValue(report, item, index))
        ) : (
          <></>
        )}
        {report?.comments && report.comments.length ? (
          <BugReportComments comments={report?.comments} />
        ) : null}
      </ReportContainer>
    </ViewContainer>
  );
};

export default ViewBugReport;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 368px;
  border-radius: 15px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.textSecondary};
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  display: flex;
  width: 450px;
  font-size: 14px;
`;
