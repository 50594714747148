import IconHome from '@components/icons/IconHome';
import React from 'react';
import { getBugsAdminMenus } from '@subApps/bugs/bugsAdminMenus';
import { getCouponsAdminMenus} from '@subApps/coupons/couponsAdminMenus';
import { MenuItemsT } from '@as_core/menus/SideMenu';
import { UserT } from '@contexts/UserContext';

export const homeMenu:MenuItemsT = {
  id: 'home',
  name: 'Home',
  pathname: '/home',
  icon: <IconHome />,
}

type getMenuT = (user: UserT) => MenuItemsT[];
export const menuFunctions: {[key: string]: getMenuT} = {
  'bugs': getBugsAdminMenus,
  'coupons': getCouponsAdminMenus,
};

export type GetLogoT = () => string;
export const logoFunctions: {[key: string]: GetLogoT} = {
};