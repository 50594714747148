import React from 'react';
import styled from 'styled-components/macro';
import { Logo, Name, Row, Title } from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';

interface AppDialogHeaderI {
  message: string;
  tooltip?: string | string[]
}

const AppDialogHeader = (props:AppDialogHeaderI) => {
  return(
    <>
      <Row height={'45px'}>
        <Logo>
          <img src={APP_CONFIG.logo} alt='logo' />
        </Logo>
        <Name>{APP_CONFIG.name}</Name>
      </Row>
      <Row height={'40px'}>
        <Title>{props.message}
        { props?.tooltip ?
          <TooltipContainer>
            <HoverInfoText text={props.tooltip} showAsBullets={false}/>
          </TooltipContainer>
          : null}
        </Title>
      </Row>
    </>
  )
}

export default AppDialogHeader;

const TooltipContainer = styled.div`
  margin-left: 16px;
`;