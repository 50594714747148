import { useState } from 'react';
import React from 'react';
import styled from 'styled-components/macro';
import InputField from '@as_core/components/inputs/InputField';
import { Row } from '@as_core/account/forms/FormStyles';
import Button from '@as_core/account/fields/Button';
import DataLoading from '@as_core/elements/DataLoading';
import useBugReports from './useBugReports';

const debug = false;
const BugReportAddComment = (props: {
  uuid: string;
  closeDialog: () => void;
}) => {
  let { uuid } = props;
  const { addBugReportComment } = useBugReports();
  debug && console.log('BugReportAddComment | uuid:', uuid);
  const [newComment, setNewComment] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdate = async () => {
    setIsLoading(true);
    await addBugReportComment(uuid, newComment);
    setIsLoading(false);
    props?.closeDialog && props.closeDialog();
  };

  const handleCancel = () => {
    props?.closeDialog && props.closeDialog();
  };

  return (
    <Container key={'new-comment-bug-report-form'}>
      {isLoading && (
        <DataLoadingContainer>
          <DataLoading />
        </DataLoadingContainer>
      )}
      <Row align={'flex-start'}>
        <InputField
          id='new-comment'
          label={'New Comment'}
          value={newComment}
          multiline
          rows={3}
          onChange={(e) => setNewComment(e.target.value)}
        />
      </Row>
      <ActionButtons>
        <Button type='cancel' label={'Cancel'} onClick={() => handleCancel()} />
        <Button
          type='submit'
          label={'Save Changes'}
          disabled={newComment === ''}
          onClick={handleUpdate}
        />
      </ActionButtons>
    </Container>
  );
};

export default BugReportAddComment;

const Container = styled.div`
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

const ActionButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
`;

const DataLoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
