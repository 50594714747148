import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import CouponsIcon from '@as_core/icons/coupons.icon';
import { UserT } from '@contexts/UserContext';
import { isUserAnAdmin } from '@as_core/account/utils/admin';

export const couponsAdminMenuItems: MenuItemsT[] = [
  {
    id: 'coupons',
    name: 'Coupons',
    pathname: '/coupons',
    icon: <CouponsIcon />,
  },
];

const debug = false;
export const getCouponsAdminMenus = (user: UserT) => {
  const isAdmin = isUserAnAdmin(user);
  if (debug) console.log('getCouponsAdminMenus | isAdmin', isAdmin);
  return couponsAdminMenuItems;
}


