import React from 'react';
import styled, { keyframes } from 'styled-components';

interface IProps {
  size?: number;
}

const Spinner = (props:IProps) => {
  const { size = 24 } = props;
  return (
    <SpinnerOverlay>
      <SpinnerStyled size={size} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </SpinnerStyled>
    </SpinnerOverlay>
  );
}

export default Spinner;

const SpinnerOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
`;

const SpinnerStyled = styled.svg<{size: number}>`
  animation: ${rotateAnimation} 2s linear infinite;
  width: ${(p) => p.size}px;
  height:  ${(p) => p.size}px;
  & .path {
    stroke: ${(p) => p.theme.palette.textPrimary};
    stroke-linecap: round;
    animation: ${dashAnimation} 1.5s ease-in-out infinite;
  }
`;





