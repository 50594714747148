import React from 'react';
import { createRoot } from 'react-dom/client'
import { StyleProvider } from "@theme/AppStyles";
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://356394615c1febce9925a8c313fa3341@o4508739797581824.ingest.us.sentry.io/4509072124674048",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://3rnd-tools.thewarriors3.com/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const render = () => {
  const App = require('./App').default;

  const container = document.getElementById('root');
  const root = createRoot(container)

  root.render(
    <StyleProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StyleProvider>
  );
};

render();