import React from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import BasicTable from '@as_core/tables/BasicTable';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import { BugCommentsT } from '@as_core/bugsReporting/bugs.types';

type UserFieldsT = {
  label: string;
  value: string;
  type: string;
  width: number;
}

const userFields: UserFieldsT[] = [
  {
    label: 'Person Commenting',
    value: 'authEmail',
    type: 'string',
    width: 200,
  },
  {
    label: 'Date',
    value: 'comment_date',
    type: 'date',
    width: 150,
  },
  {
    label: 'Comment',
    value: 'comment',
    type: 'comment',
    width: 100,
  },
];

function getValue(comment: BugCommentsT, item: UserFieldsT) {
  if (item?.type === 'boolean') {
    return _.get(comment, item.value, '').toString();
  } else if (item?.type === 'date') {
    const value = _.get(comment, item.value, '');
    if (value === '') return '';
    const utc = new Date(value);
    return utc.toLocaleDateString() + ' ' + utc.toLocaleTimeString();
  } else if (item?.type === 'comment') {
    const value = _.get(comment, item.value, '');
    return <HoverInfoText text={value} />;
  }
  return _.get(comment, item.value, '');
}

function getTableRows(comments: BugCommentsT[]) {
  const rows = [];
  comments.forEach((c) => {
    const row = {};
    userFields.forEach((f) => {
      row[f.value] = getValue(c, f);
    });
    rows.push(row);
  });
  return rows;
}

const debug = false;
const BugComments = (props: { comments: BugCommentsT[] }) => {
  let { comments } = props;
  debug && console.log('BugReportComments | comments:', comments);

  return (
    <CommentsContainer>
      <Comments>Bug Report Comments:</Comments>
      <BasicTable fields={userFields} rows={getTableRows(comments)} />
    </CommentsContainer>
  );
};

export default BugComments;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border-top: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const Comments = styled.div`
  display: flex;
  width: calc(100% - 20px);
  padding-left: 10px;
  font-size: ${(p) => p.theme.sizes.xsmall};
`;
