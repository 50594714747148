import { CouponCreateT, CouponT, CouponUpdateT } from './coupons.types';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { processAxiosResponse } from '@utils/api/utils';
import useCognito from "@as_core/account/useCognito";
import { authCoreClient } from '@utils/api/base';

export const Coupons = {
  get: (token, config) => authCoreClient(token).get("/coupons", config),
  getAll: (token) => authCoreClient(token).get("/coupons/list", null),
  create: (token, data) => authCoreClient(token).post("/coupons/create", data),
  update: (token, data) => authCoreClient(token).patch("/coupons/update", data),
  delete: (token, data) => authCoreClient(token).delete('/coupons/delete', data)
};

const useCoupons = () => {
  const { getToken } = useCognito();

  const getCoupon = async (code: string): Promise<CouponT> => {
    const params = { code: code}
    const config: AxiosRequestConfig = { params };
    const resp: AxiosResponse<CouponT> = await Coupons.get(getToken(), config);
    return processAxiosResponse('getCoupon', resp);
  };

  const getAllCoupons = async (): Promise<CouponT[]> => {
    let resp: AxiosResponse<CouponT[]> = await Coupons.getAll(getToken());
    return processAxiosResponse('getAllCoupons', resp);
  };

  const createCoupon = async (params: CouponCreateT): Promise<CouponT> => {
    let resp: AxiosResponse<CouponT> = await Coupons.create(getToken(), params);
    return processAxiosResponse('createCoupon', resp);
  };

  const updateCoupon = async (params: CouponUpdateT): Promise<CouponT> => {
    let resp: AxiosResponse<CouponT> = await Coupons.update(getToken(), params);
    return processAxiosResponse('updateCoupon', resp);
  };

  const deleteCoupon = async (id: string): Promise<null> => {
    const token = getToken();
    const params = {'id': id}
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<null> = await Coupons.delete(token, config);
    return processAxiosResponse('updateCoupon', resp);
  };

  return {
    getCoupon,
    getAllCoupons,
    createCoupon,
    updateCoupon,
    deleteCoupon
  };
}

export default useCoupons;