import React, { useContext, useEffect, useState } from 'react';
import SideNav from './SideNav';
import { UserContext, UserT } from '@contexts/UserContext';
import { useLocation } from 'react-router-dom';
import { homeMenu, menuFunctions, logoFunctions } from '@app_config/sideMenu';
import { APP_CONFIG } from '@app_config/app';

const debug = false;

export function getLogo(user: UserT): string {
  const { subApp } = user;

  if (debug) console.log('SideMenu | getLogo: ', subApp);
  let logo = APP_CONFIG.logo;
  if (Object.hasOwn(logoFunctions, subApp)) {
    logo = logoFunctions[subApp]();
  }
  return logo
}

export type MenuItemsT = {
  id: string;
  name: string;
  pathname: string | string[];
  icon: React.ReactNode;
  subscriptions?: string[];
}

export function getMenus(user: UserT): MenuItemsT[] {
  const { subApp } = user;

  if (debug) console.log('SideMenu | getMenu: ', subApp, user);
  let menus = [homeMenu];
  if (Object.hasOwn(menuFunctions, subApp)) {
    menus = [ ...menus, ...menuFunctions[subApp](user)];
  }
  return menus;
}

function inPath(path: string, menuArray: string[]): boolean {
  let found = false;
  menuArray.forEach((menu) => {
    if (path.includes(menu)) found = true;
  })
  return found;
}


export function getActiveMenuId(menus:MenuItemsT[], path: string): string {
  if (debug) console.log('SideMenu | getActiveMenuId: ', menus, path);
  const activeMenu = menus.find(menu => Array.isArray(menu.pathname) ?
    inPath(path, menu.pathname) : path.includes(menu.pathname));
  if (activeMenu) {
    return activeMenu.id;
  }
  return '';
}

const SideMenu = () => {
  const [activeMenus, setActiveMenus] = useState([]);
  const { user } = useContext(UserContext);
  const [sideLogo, setSideLogo] = useState<string>(getLogo(user));
  const { subApp } = user;
  const location = useLocation();
  const path = location.pathname.split('?')[0];
  const activeMenuId = getActiveMenuId(activeMenus, path);

  useEffect(() => {
    setActiveMenus(getMenus(user));
    setSideLogo(getLogo(user))
  }, [subApp]);

  if (debug) console.log('SideNav | {activeMenus, path, activeMenuId}:', activeMenus, path, activeMenuId);
  return <SideNav menus={activeMenus} activeMenuId={activeMenuId} sideLogo={sideLogo} />;
};

export default SideMenu;
