import React, { useState, useEffect } from 'react';
import { FlexColumn, FlexItem } from '@as_core/elements/flexStyles';
import DraggableModal from '@components/elements/DraggableModal';
import ViewBugReport from '@subApps/bugs/components/ViewBugReport';
import BugReportAddComment from './BugReportAddComment';
import BasicTable from '@as_core/tables/BasicTable';
import { ColDef, getReportTableRows } from '../utils';
import UpdateBugReport from './UpdateBugReport';
import SearchInput from '@components/elements/inputs/SearchInput';
import { BugReportT } from '@as_core/bugsReporting/bugs.types';

const modalDimensions = {
  new: { height: 230, width: 500 },
  unassigned: { height: 200, width: 500 },
  active: { height: 250, width: 450 },
};

interface PropsI {
  action: string,
  system: string,
  bugReports: BugReportT[]
}

const debug = false;
const BugsTable = (props: PropsI) => {
  const { action, system, bugReports } = props;
  const [bugRows, setBugRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  debug && console.log('BugsTable | selectedReport:', selectedReport);

  const handleModalClose = () => setIsModalOpen(false);

  const handleClick = (type: string, uuid: string) => {
    debug && console.log('handleClick ', type, uuid);
    const matched = bugReports.filter((r) => r.uuid === uuid);
    if (matched.length) {
      setSelectedReport(matched[0]);
      debug && console.log('handleEditClick: matched', matched);
      switch (type) {
        case 'edit':
          setIsModalOpen(true);
          break;
        case 'comment':
          setIsCommentModalOpen(true);
          break;
        default:
          setIsViewModalOpen(true);
      }
    }
  };

  const onHandleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filterReports = (reports, query: string) => {
    if (!query) return reports;
    const lowerQuery = query.toLowerCase();

    return reports.filter((report) => {
      return (
        report.title?.toLowerCase().includes(lowerQuery) ||
        report.name?.toLowerCase().includes(lowerQuery) ||
        report.reference?.toLowerCase().includes(lowerQuery) ||
        report.resolution?.assigned?.toLowerCase().includes(lowerQuery) ||
        report.submit.submitter.email?.toLowerCase().includes(lowerQuery)
      );
    });
  };

  useEffect(() => {
    const filteredReports = filterReports(bugReports, searchValue);

    setBugRows(getReportTableRows(action, filteredReports, handleClick));
  }, [action, bugReports, searchValue]);

  const cols = Object.hasOwn(ColDef, action) ? ColDef[action] : ColDef['new'];

  debug && console.log('BugsTable: {action}', action);
  debug && console.log('BugsTable: {bugReports}', bugReports);
  debug && console.log('BugsTable: {bugRows}', bugRows);
  debug && console.log('BugsTable: {cols}', cols);

  return (
    <>
      <FlexColumn h_centered margin='auto' gap='8px'>
        <FlexItem width='100%'>
          <SearchInput
            label='Search Bugs'
            value={searchValue}
            onChange={onHandleSearch}
          />
        </FlexItem>
        <FlexItem>
          <BasicTable fields={cols} rows={bugRows} />
        </FlexItem>
      </FlexColumn>
      <DraggableModal
        title={'Update Bug Report'}
        height={
          Object.hasOwn(modalDimensions, action)
            ? modalDimensions[action].height
            : 600
        }
        width={
          Object.hasOwn(modalDimensions, action)
            ? modalDimensions[action].width
            : 600
        }
        isModalOpen={isModalOpen}
        onCloseClick={handleModalClose}
      >
        <UpdateBugReport
          action={action}
          system={system}
          report={selectedReport}
          closeDialog={() => setIsModalOpen(false)}
        />
      </DraggableModal>
      <DraggableModal
        title={'View Full Bug Report'}
        isModalOpen={isViewModalOpen}
        onCloseClick={() => setIsViewModalOpen(false)}
      >
        <ViewBugReport report={selectedReport} />
      </DraggableModal>
      <DraggableModal
        title={'Add Comment to Bug Report'}
        isModalOpen={isCommentModalOpen}
        onCloseClick={() => setIsCommentModalOpen(false)}
        height={200}
      >
        <BugReportAddComment
          uuid={selectedReport?.uuid}
          closeDialog={() => setIsCommentModalOpen(false)}
        />
      </DraggableModal>
    </>
  );
};

export default BugsTable;
