import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import styled from 'styled-components/macro';

export interface ISelectOption {
  value: string;
  label: string;
}

interface ISelectField {
  label: string;
  value: string;
  labelId: string;
  onChange: (value: any) => void;
  id?: string;
  size?: 'small' | 'medium';
  options: ISelectOption[];
  error?: boolean;
  fullWidth?: boolean;
}

const SelectField = (props: ISelectField) => {
  const { label, options, labelId, onChange, fullWidth } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <StyledSelect onChange={onChange} id={labelId} label={label} {...props}>
        {options.map((option, i) => (
          <MenuItem key={`${option.value}+${i}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectField;

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px !important;
  font-size: ${(p) => p.theme.sizes.small};
`;
