import React, { useContext } from 'react';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '../components/pages/PagePanel';
import PageTiles from '@components/layout/PageTiles';
import { UserContext } from '@contexts/UserContext';
import { asedaCards, toolsCards } from '@app_config/homeCards';

const debug = false;
// Show the main page of tiles for the site (linked to the logo)
const PgHome = () => {
  const { user, setUser } = useContext(UserContext);
  debug && console.log('PgHome | userInfo:', user);

  if (user.subApp !== '') {
    setUser((prev) => ({
      ...prev, subApp: ''
    }));
  }

  return (
    <PageContainer title='Welcome'>
      <PagePanel title={'3RnD/AsedaSciences Tools'} width={'98%'}>
        <PageTiles isAuthorized={user.isAppAuthorized} cards={toolsCards} />
      </PagePanel>
      <PagePanel title={'AsedaSciences Active Applications'} width={'98%'}>
        <PageTiles isAuthorized={user.isAppAuthorized} cards={asedaCards} />
      </PagePanel>
    </PageContainer>
  );
};

export default PgHome;
