import React, { useState } from 'react';
import { UpdateBugReportT } from '@as_core/bugsReporting/bugs.types';
import {
  BasicTextInput,
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import { Checkbox } from '@mui/material';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import TextButton from '@as_core/controls/buttons/TextButton';

type AcknowledgeUpdatesT = {
  'submit.acknowledged': string,
  'resolution.deferred': string,
  bug_reference: string,
}

type PropsT = {
  handleUpdate?: (updates: UpdateBugReportT) => void;
  handleCancel?: () => void;
}

const debug = false;
export const Acknowledge = (props:PropsT) => {
  const { handleUpdate} = props;
  const [updates, setUpdates] = useState<AcknowledgeUpdatesT>({
    'submit.acknowledged': 'true',
    'resolution.deferred': 'false',
    bug_reference: '',
  });

  const _handleUpdates = (field:string, value:string) => {
    debug && console.log(field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const _handleCheckboxUpdates = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug && console.log(event.target.checked);
    const state = event.target.checked ? 'true' : 'false';
    setUpdates((prev) => ({...prev, 'resolution.deferred': state }));
  }

  const handleCancel = () => {
    debug && console.log('Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  debug && console.log('Acknowledge | updates', updates);
  const disabled = updates.bug_reference === '' && updates['resolution.deferred'] === 'false';

  return (
    <Container key={'acknowledge-bug-report-form'}>
      <Row width='300px' align={'flex-start'} >
        <FormLabel width={100}>JIRA Reference:</FormLabel>
        <FlexItem>
          <BasicTextInput
            id='acknowledge_ref'
            type='string'
            name='ref'
            placeholder='Enter JIRA Ref ...'
            value={Object.hasOwn(updates, 'bug_reference') ? updates['bug_reference'] : ''}
            onChange={(e) => _handleUpdates('bug_reference', e.target.value)}
          />
        </FlexItem>
      </Row>
      <Row width='300px' align={'flex-start'}>
        <Checkbox
          checked={updates['resolution.deferred'] === 'true'}
          onChange={_handleCheckboxUpdates}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          size='small'
        />
        <FormLabel>Defer Bug Report for Future Consideration
          <HoverInfoText text={'This is not a critical bug, but a feature request. It will go to deferred bucket, but not be lost.'}/>
        </FormLabel>
      </Row>
      <ActionButtons>
        <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <TextButton
          width={100}
          label={'Save Changes'}
          isDisabled={disabled}
          onClick={disabled ? ()=>{} : () => handleUpdate(updates)}
        />
      </ActionButtons>
    </Container>
  );
};

export default Acknowledge;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;