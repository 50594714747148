import { authAppClient } from "./base";

export const ApiInfo = {
  get: (token) => authAppClient(token).get('/info/'),
};

export const User = {
  get: (token, config) => authAppClient(token).get('/user', config),
  create: (token, data) => authAppClient(token).post('/user/create', data),
  update: (token, data) => authAppClient(token).post('/user/update', data),
  delete: (token, data) => authAppClient(token).post('/user/delete', data),
};

