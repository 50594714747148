import styled from 'styled-components/macro';
import useBugReports from '@as_core/bugsReporting/useBugReports';
import { BugReportT, UpdateBugReportT } from '@as_core/bugsReporting/bugs.types';
import { updateByDot } from '@subApps/bugs/utils';
import ReportInfoHeader from './ReportInfoHeader';
import Acknowledge from '@subApps/bugs/forms/Acknowledge';
import AssignDeveloper from '@subApps/bugs/forms/AssignDeveloper';
import CloseReport, { CloseReportUpdatesT } from '@subApps/bugs/forms/CloseReport';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function getForm(action: string, handleUpdate: (u: UpdateBugReportT | CloseReportUpdatesT) => void, handleCancel: () => void) {
  switch(action) {
    case 'new':
      return (
        <Acknowledge
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'unassigned':
      return(
        <AssignDeveloper
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    case 'active':
      return(
        <CloseReport
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    default:
      console.error('UpdateBugReport | unknown form for action', action);
  }
}

type PropsT = {
  action: string;
  system: string;
  report: BugReportT;
  closeDialog?: () => void;
}

const debug = false;
const UpdateBugReport = (props:PropsT) => {
  let { action, system, report } = props;
  const { updateBugReport } = useBugReports();
  const queryClient = useQueryClient();
  debug && console.log('UpdateBugReport | action, report:', action, report);

  const mutationBugReport = useMutation({
    mutationFn: (updates: UpdateBugReportT) => handleBugReportUpdate(updates),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bugCounts', action] }).then();
      queryClient.invalidateQueries({ queryKey: ['bugReports', system, action] }).then();
    },
  });

  const handleBugReportUpdate = async (updates: UpdateBugReportT) => {
    const payload= {};
    Object.keys(updates).forEach((k) => {updateByDot(payload, k, updates[k])});
    await updateBugReport(report.uuid, payload);
  }

  const _handleUpdate = async (updates: UpdateBugReportT) => {
    mutationBugReport.mutate(updates);
    props?.closeDialog && props.closeDialog();
  }

  return (
    <UserEditContainer>
      <ReportInfoHeader report={report} />
      { getForm(action, _handleUpdate, props?.closeDialog) }
    </UserEditContainer>
  );
};

export default UpdateBugReport;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
