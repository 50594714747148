import { authCoreClient } from '@utils/api/base';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { processAxiosResponse } from '@utils/api/utils';
import useCognito from '@as_core/account/useCognito';
import { BugReportsParamsT, BugReportT } from '@as_core/bugsReporting/bugs.types';

export const BugReportsApi = {
  get: (token: string, config) =>
    authCoreClient(token).get('/bugs/', config),
  submit: (token: string, data) =>
    authCoreClient(token).post('/bugs/submit', data),
  update: (token: string, uuid, data) =>
    authCoreClient(token).patch(`/bugs/update/${uuid}`, data),
  delete: (token: string, uuid) =>
    authCoreClient(token).delete(`/bugs/delete/${uuid}`),
  addComment: (token: string, uuid, data) =>
    authCoreClient(token).patch(`/bugs/add/comment/${uuid}`, data),
  getReportsCount: (token: string, bug_type: string) =>
    authCoreClient(token).get(`/bugs/count?bug_type=${bug_type}`),
};

const useBugReports = () => {
  const { getToken } = useCognito();

  const getBugReports = async (params: BugReportsParamsT): Promise<BugReportT[]> => {
    const config: AxiosRequestConfig = { params };
    let resp: AxiosResponse<BugReportT[]> = await BugReportsApi.get(getToken(), config);
    return processAxiosResponse('getBugReports', resp);
  };

  const getBugReportCounts = async (bug_type: string): Promise<{ [key: string]: number }> => {
    let resp: AxiosResponse<{ [key: string]: number }> = await BugReportsApi.getReportsCount(getToken(), bug_type);
    return processAxiosResponse('getBugReportCounts', resp);
  };

  const createBugReport = async (params: BugReportsParamsT): Promise<BugReportT> => {
    let resp: AxiosResponse<BugReportT> = await BugReportsApi.submit(getToken(), params);
    return processAxiosResponse('createBugReport', resp);
  };

  const updateBugReport = async (
      bug_id: string,
      params: { [key: string]: string | { [key: string]: string } },
  ): Promise<BugReportT> => {
    let resp: AxiosResponse<BugReportT> = await BugReportsApi.update(getToken(), bug_id, params);
    return processAxiosResponse('updateBugReport', resp);
  };

  const addBugReportComment = async (
    bug_id: string,
    comment: string
  ): Promise<BugReportT> => {
    const params = {"comment": comment};
    let resp: AxiosResponse<BugReportT> = await BugReportsApi.addComment(getToken(), bug_id, params);
    return processAxiosResponse('addBugReportComment', resp);
  };

  const deleteBugReport = async (
    bug_id: string,
  ): Promise<BugReportT> => {
    let resp: AxiosResponse<BugReportT> = await BugReportsApi.delete(getToken(), bug_id);
    return processAxiosResponse('deleteBugReport', resp);
  };

  return {
    getBugReports,
    getBugReportCounts,
    createBugReport,
    updateBugReport,
    addBugReportComment,
    deleteBugReport
  };
}

export default useBugReports;
