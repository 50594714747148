import React, {memo, ReactNode} from 'react';
import { Tooltip } from '@mui/material';

export type TooltipPlacementT = "right" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "top-end" | "top-start" | "top";

interface TextButtonPropsT {
  tooltip?: string,
  tooltipPlacement?: TooltipPlacementT,
  label: string,
  icon?: ReactNode,
  onClick?: (e) => void,
  isActive?: boolean,
  isDisabled?: boolean,
  iconRight?: boolean,
  margin?: string,
  fontSize?: number,
  width?: number,
  height?: number,
}

const TextButton = (props: TextButtonPropsT) => {

  return (
      <Tooltip
        title={props.label}
        placement={props?.tooltipPlacement ? props.tooltipPlacement : 'right'}
        arrow
      >
        <TextButton {...props} />
      </Tooltip>
  );
}
export default memo(TextButton);