import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { APP_CONFIG } from '@app_config/app';
import { Row, Column, StyledA, StyledForm, LoginButton } from './FormStyles';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import useCognito from '@as_core/account/useCognito';
import Button from '../fields/Button';
import { isValidEmail } from '../utils';
import InputField from '@as_core/components/inputs/InputField';

import AppDialogHeader from '@as_core/account/components/AppDialogHeader';

/**
 * Component to handle the login functionality for AsedaSciences Apps through
 * AWS Cognito -- sets user context upon success and/or other states
 * @constructor
 */

const debug = false;
const VerifyEmail = () => {
  const [email, setEmail] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { verifyEmail, resendVerificationCode } = useCognito();

  // check for url to redirect as parameter - on mount
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get('email');
    if (debug) console.log('query email ', email);
    if (email) {
      setEmail(email);
    }
  }, []);

  // function to handle the submit action if valid form and not in loggingIn state
  const handleSendCode = () => {
    if (!isValidEmail(email)) {
      setErrorMessage('Not valid email - entered incorrectly');
    } else {
      setErrorMessage('');
      verifyEmail(email, verificationCode)
        .then((result) => {
          console.log('verifyEmail | result: ', result);
          setSuccess(true);
          // safely ignore result
        })
        .catch((error) => {
          setSuccess(false);
          setErrorCode(error);
          console.error('errorCode: ', error);
        });
    }
  };

  // function to handle resending the code -- user email is already set in component
  const handleResendCode = () => {
    setErrorMessage('');
    resendVerificationCode(email)
      .then((result) => {
        console.log('verifyEmail | result: ', result);
        setSuccess(true);
        // safely ignore result
      })
      .catch((error) => {
        setSuccess(false);
        setErrorCode(error);
        console.error('errorCode: ', error);
      });
  };

  // function to handle the submit action if valid form and not in loggingIn state
  const handleErrorCode = (code: string) => {
    console.log('handleErrorCode: ', code);
    switch (code) {
      case 'ExpiredCodeException':
        return getResendCodeForm();
      default:
        alert('Unknown code: ' + code);
        console.error('handleErrorCode | unhandled code:', code);
    }
  };

  // code for entering email to send verification code to user
  function getVerifyEmailForm() {
    return (
      <>
        <Row height={'16px'}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        </Row>
        <StyledForm key={'verify-email-form'}>
          <InputField
            id='username'
            type='text'
            label='Email Address'
            name='username'
            placeholder='Enter account email to send verification code ...'
            autoComplete='username'
            error={errorMessage !== ''}
            value={email}
            size='small'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <InputField
            id='code'
            type='text'
            label='Verification Code'
            name='code'
            placeholder='Enter code from email ...'
            error={errorMessage !== ''}
            value={verificationCode}
            size='small'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setVerificationCode(e.target.value)
            }
          />
        </StyledForm>
        <LoginButton>
          <Button
            type={'submit'}
            label={'Verify Email'}
            onClick={handleSendCode}
          />
        </LoginButton>
      </>
    );
  }

  function getResendCodeForm() {
    return (
      <>
        <Column>
          <StatusMessage>Current Code is Expired or Used.</StatusMessage>
          <StatusMessage>Click below to send a new Code.</StatusMessage>
        </Column>
        <LoginButton>
          <Button
            type={'submit'}
            label={'Send New Verification Code'}
            onClick={handleResendCode}
          />
        </LoginButton>
        <Row height={'16px'}>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        </Row>
      </>
    );
  }

  // use on final success
  function getSuccess() {
    return (
      <Column>
        <StatusMessage>Successfully verified email account.</StatusMessage>
        <StatusMessage>Please login and proceed to registration.</StatusMessage>
      </Column>
    );
  }

  // component render
  return (
    <Container key={'verify-email-form'}>
      <AppDialogHeader
        message={'Verify Email via Code'}
        tooltip={APP_CONFIG.verifyEmailInfo}
      />
      {success
        ? getSuccess()
        : errorCode !== ''
        ? handleErrorCode(errorCode)
        : getVerifyEmailForm()}
      <Row>
        <StyledA key={'return'} href={'/user/login'} size={'12px'}>
          return to sign-in
        </StyledA>
      </Row>
    </Container>
  );
};

export default VerifyEmail;

const Container = styled.div`
  width: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const StatusMessage = styled.div`
  color: ${(p) => p.theme.palette.accentSecondary};
  width: 100%;
  font-size: 16px;
  font-style: italic;
`;
