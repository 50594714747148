import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import HeaderTab, { actionT } from '@as_core/pages/HeaderTab';

function getMenuFromCounts(counts:SystemCountsT, handleAction: (v:string) => void): actionT[] {
  const newActionItems: actionT[] = [];
  const systems: string[] = Object.keys(counts).sort();
  systems.forEach((key) => {
    newActionItems.push({
      key: key,
      label: `${key} (${counts[key]})`,
      action: handleAction,
    })
  })
  return newActionItems;
}

type SystemCountsT = {
  [key: string]: number;
}

type PropsT = {
  systemCounts: SystemCountsT;
  system: string;
  setSystem: (v:string) => void;
}
const debug = false;
const SystemSelector = (props:PropsT) => {
  const { systemCounts, system, setSystem } = props;
  const [menuItems, setMenuItems] = useState<actionT[]>(getMenuFromCounts(systemCounts, setSystem));
  if (debug) console.log('SystemSelector | systemCounts:', systemCounts, menuItems);
  if (props.systemCounts === null || !Object.keys(props.systemCounts).length) {
    return(<></>)
  }

  useEffect(() => {
    setMenuItems(getMenuFromCounts(systemCounts, setSystem));
  }, [systemCounts]);

  return (
    <>
      { menuItems.length ?
        <HeaderTab
          headerActions={menuItems}
          selectedTab={system}
        />
        :
        <HeaderMessage>
          No Bug Reports of this level found for any System
        </HeaderMessage>
      }
    </>

  )
}

export default SystemSelector;

const HeaderMessage = styled.div`
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  padding: 0;
  height: 32px;
`;
