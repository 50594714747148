import React from "react";
import HeaderMenu from '../controls/HeaderMenu';
import styled from 'styled-components/macro';

export type actionT = {
  key: string;
  label: string;
  action: (v: string) => void;
};

interface propsT {
  headerActions: actionT[];
  selectedTab: string;
}

const HeaderTab = (props: propsT) => {
  const {headerActions, selectedTab} = props;
  return (
      <HeaderBanner>
        {headerActions.map((action) => (
          <HeaderMenu
            key={`user_data_${action.key}`}
            fontSize={16}
            label={action.label}
            isActive={action.key === selectedTab}
            onClick={() => action.action(action.key)}
          />
        ))}
      </HeaderBanner>
  );
};

export default HeaderTab;

export const HeaderBanner = styled.div`
  display: flex;
  padding: 0;
  height: max-content;
  width: 100%;
`;