import React from 'react';
import { useEffect, useState } from 'react';
import { TextSmall } from '@components/elements/TextStyles';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { Checkbox } from '@mui/material';
import styled from 'styled-components/macro';

interface CheckBoxT {
  text?: string;
  selected: boolean;
  onChange?: (v: boolean) => void;
  disabled?: boolean;
}

const debug = false;
const CheckBox = ({ text = "", selected, onChange, disabled }: CheckBoxT) => {
  const [checked, setChecked] = useState<boolean>(selected);

  const handleChange = (e) => {
    if (!disabled) {
      if (debug) console.log('CheckBox | handleChange {e}', e.target.value);
      onChange(!checked);
      setChecked((prev) => !prev);
    }
  };

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <FlexRow v_centered>
      <FlexItem>
        <StyledCheckbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          size='small'
          disabled={disabled}
        />
      </FlexItem>
      {text ? (

        <FlexItem>
          <StyledTextSmall
            onClick={handleChange}
            color={checked ? 'accentSecondary' : 'default'}
            disabled={disabled}
          >
            {text}
          </StyledTextSmall>
        </FlexItem>
      ) : null
      }
    </FlexRow>
  );
};

const StyledTextSmall = styled(TextSmall) <{ disabled: boolean }>`
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;

const StyledCheckbox = styled(Checkbox) <{ disabled: boolean }>`
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')} !important;
`;

export default CheckBox;
