import { useState } from 'react';
import {
  BasicTextArea,
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import TextButton from '@as_core/controls/buttons/TextButton/TextButton';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';

type PropsT = {
  handleUpdate: (comment: string) => void;
  handleCancel?: () => void;
}

const debug = false;
export const AddComment = (props:PropsT) => {
  const [newComment, setNewComment] = useState<string>('');

  const handleCancel = () => {
    debug && console.log('Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  debug && console.log('Acknowledge | newComment', newComment);

  const disabled = newComment === ''

  return (
    <Container key={'new-comment-bug-report-form'}>
        <Row width='450px' align={'flex-start'}>
          <FormLabel width={110}>New Comment:</FormLabel>
          <FlexItem>
            <BasicTextArea
              key='new_comment'
              name='comment'
              width={400}
              height={100}
              placeholder='enter new comment ...'
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
          </FlexItem>
        </Row>
        <ActionButtons>
          <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
          <TextButton
            width={100}
            label={'Save Changes'}
            isDisabled={disabled}
            onClick={disabled ? ()=>{} : () => props.handleUpdate(newComment)}
          />
        </ActionButtons>
    </Container>
  );
};

export default AddComment;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;