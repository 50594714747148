import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@contexts/UserContext';
import { APP_CONFIG } from '@app_config/app';
import { TextSmall, TextSpace } from '@components/elements/TextStyles';
import {
  Row,
  StyledForm,
  StyledA,
  LoginButton,
  ForgotPasswordA,
} from './FormStyles';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import useCognito from '@as_core/account/useCognito';
import Button from '@as_core/account/fields/Button';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import { isValidEmail } from '@as_core/account/utils';
import { errorMessages } from './config';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';
import InputField from '@as_core/components/inputs/InputField';

import { useNavigate } from 'react-router-dom';
/**
 * Component to handle the login functionality for AsedaSciences Apps through
 * AWS Cognito -- sets user context upon success and/or other states
 * @constructor
 */
interface PropsI {
  setMessage: (string) => void;
}

const debug: boolean = false;
const SignIn = ({ setMessage }:PropsI) => {
  const { user } = useContext(UserContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { login } = useCognito();

  if (debug) console.log('SignIn | user:', user, 'errorMessage:', errorMessage);

  // function to handle the submit action if valid form and not in loggingIn state
  // if user is not confirmed (email verified) -- it will show as an error and redirect to verify-user form
  const handleLogin = async () => {
    if (debug) console.log('handleLogin', email, password);
    setErrorMessage('');
    if (email && !isValidEmail(email)) {
      setErrorMessage(errorMessages.invalidEmailFormat);
    } else if (password?.length < 8) {
      setErrorMessage(errorMessages.invalidPasswordLength);
    } else {
      // setMessage('Authenticating');
      setIsAuthenticating(true);
      login(email, password)
        .then(() => {
          setIsAuthenticating(false);
        })
        .catch((error) => {
          setMessage('');
          if (error === 'Incorrect username or password.') {
            setErrorMessage(
              'Incorrect email/password or user needs to create an account'
            );
          } else if (error === 'User is not confirmed.') {
            navigate(`/user/verify-email?email=${email}`);
          } else {
            setErrorMessage(error);
          }
          setIsAuthenticating(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    // remove error message if there is any
    if (errorMessage) {
      setErrorMessage('');
    }
    // Check if the pressed key was "Enter"
    if (e.key === 'Enter') {
      handleLogin().then();
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.name, event.target.value);
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }

    if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
  };

  return (
    <Container>
      <AppDialogHeader message={'Log in to your Account'} />
      <Row>
        <TextSmall>
          Or <StyledA href={'/user/new'}>create an account</StyledA> if you are
          a new user
        </TextSmall>
        <TextSpace />
        <HoverInfoText text={APP_CONFIG.loginInfo} showAsBullets={false} />
      </Row>
      <StyledForm onSubmit={handleLogin}>
        <ErrorContainer>
          {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
        </ErrorContainer>
        <InputField
          value={email}
          onChange={onChange}
          placeholder='Enter email as username ...'
          name='email'
          autoComplete='email'
          onKeyDown={handleKeyDown}
          label='Email Address'
          error={Boolean(errorMessage)}
          size='small'
        />
        <InputField
          id='signin_password'
          type='password'
          name='password'
          placeholder='Enter password ...'
          autoComplete='password'
          error={Boolean(errorMessage)}
          value={password}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          label='Password'
          size='small'
        />
        <LoginButton>
          <Button
            type='submit'
            label={'Login'}
            onClick={handleLogin}
            inProcess={isAuthenticating}
          />
        </LoginButton>
        <ForgotPasswordA href={'/user/forgot'} tabIndex={-1}>
          Forgot Password
        </ForgotPasswordA>
      </StyledForm>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  width: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
  position: relative;
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  height: 24px;
  margin: auto;
  width: max-content;
`;
