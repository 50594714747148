import { Tooltip } from '@mui/material';
import { truncateString } from '@utils/misc';
import React from 'react';
import { BugReportT } from '@as_core/bugsReporting/bugs.types';
import _ from 'lodash';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import MenuButton from '@components/elements/MenuButton';
import EditIcon from '@as_core/icons/edit.icon';
import ViewIcon from '@as_core/icons/view.icon';
import AddCommentIcon from '@as_core/icons/addComment.icon';
import { Link } from 'react-router-dom';

export type ReportColT = {
  value: string,
  label: string,
  key: string,
  type: string,
  width: number
}

export function updateByDot(obj, key, value) {
  const parts = key.split('.')
  let obj_ref = obj;
  while (parts.length) {
    const part = parts.shift();
    if (!parts.length) {
      obj_ref[part] = value;
    } else {
      obj_ref = obj_ref[part] = obj_ref[part] || {};
    }
  }
}

export const ColDef: {[key:string]: ReportColT[]} = {
  'new': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 80 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'category', label: 'Category', key: 'category', type: 'string', width: 200 },
    { value: 'submitter', label: 'Submitted By', key: 'submit.submitter.authEmail', type: 'string', width: 200 },
    { value: 'submitted', label: 'Submitted On', key: 'submit.time_submitted', type: 'time', width: 150 },
  ],
  'unassigned': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 100 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'category', label: 'Category', key: 'category', type: 'string', width: 150 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'reference', width: 80 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.authEmail', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'date', width: 80 },
    { value: 'acknowledged', label: 'Ack\'d', key: 'submit.time_acknowledged', type: 'date', width: 80 },
  ],
  'active': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 75 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'reference', width: 75 },
    { value: 'assigned_to', label: 'Assigned To', key: 'resolution.assigned', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.authEmail', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'date', width: 80 },
    { value: 'acknowledged', label: 'Ack\'d', key: 'submit.time_acknowledged', type: 'date', width: 80 },
    { value: 'assigned', label: 'Assigned', key: 'resolution.time_assigned', type: 'date', width: 80 },
  ],
  'closed': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 75 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'reference', width: 75 },
    { value: 'assigned_to', label: 'Assigned To', key: 'resolution.assigned', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.authEmail', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'date', width: 80 },
    { value: 'resolved', label: 'Resolved', key: 'resolution.time_resolved', type: 'date', width: 80 },
  ],
  'deferred': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 75 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'reference', width: 75 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.authEmail', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'time', width: 200 },
  ]
};

export const getBugStatus = (report: BugReportT) => {
  let status = 'submitted';
  if (report?.submit?.acknowledged) {
    status = 'acknowledged';
  }
  if (report?.resolution?.assigned) {
    status = 'active';
  }
  if (report?.resolved) {
    status = 'closed';
  }
  if (report?.resolution?.deferred && report.resolution.deferred) {
    status = 'deferred';
  }
  return status;
};

export const getStringHover = (text: string, width: number) => {
  if (typeof(text) !== 'string') return '';
  const characters = Math.floor(width/4);
  if (text.length < characters) return text;
  return(
    <Tooltip title={text}>
      { truncateString(text, characters)}
    </Tooltip>
  )
}

export const getTitleInfo = (report: BugReportT) => {
  return(
    <>
      {_.get(report, 'title', '') + ' ' }
      <HoverInfoText text={_.get(report, 'description', '')}/>
    </>
  )
}

export const getReferenceLink = (value: string) => {
  const url = 'https://asedasciences.atlassian.net/browse/' + value;
  if (value === '') return '';
  return(
    <Link to={url} target='_blank'>{ value } </Link>
  )
}


// Return the display value for the column from the BugReport
export const getDisplayBugValue = (col: ReportColT, report:BugReportT, handleClick) => {
  if (col.value === 'status') {
    return getBugStatus(report);
  } else if (col.value === 'title') {
    return (getTitleInfo(report));
  } else if (col.value === 'edit') {
    return (
      <MenuButton
        onClick={() => handleClick('edit', report?.uuid)}
        icon={<EditIcon />}
        text={'Update BugReport'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.value === 'view') {
    return (
      <MenuButton
        onClick={() => handleClick('view', report?.uuid)}
        icon={<ViewIcon />}
        text={'View Full BugReport'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.value === 'comment') {
    return (
      <MenuButton
        onClick={() => handleClick('comment', report?.uuid)}
        icon={<AddCommentIcon />}
        text={'Add Comment to BugReport'}
        tooltipPlacement={'bottom'}
      />
    )
  } else {
    const value = _.get(report, col.key, '');
    if (col.type === 'time') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString() + ' ' + utcDate.toLocaleTimeString();
    } else if (col.type === 'date') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString();
    } else if (col.type === 'reference') {
      return getReferenceLink(value)
    } else {
      return getStringHover(value, col.width);
    }
  }
}

export const getReportTableRows = (action: string, bugReports: any[], handleEditClick: (t:string, u:string)=>void) => {
  let rows = [];
  for (const report of bugReports) {
    let row = {};
    const cols = Object.hasOwn(ColDef, action) ? ColDef[action] : ColDef['new'];
    for (const col of cols) {
      row[col.value] = getDisplayBugValue(col, report, handleEditClick);
    }
    rows.push(row);
  }
  return rows;
};

export const getTitle = (action: string): string => {
  switch(action) {
    case 'new':
      return 'New Bug Reports';
    case 'unassigned':
      return 'Unassigned Bug Reports';
    case 'active':
      return 'Active Bug Reports';
    case 'closed':
      return 'Closed Bug Reports';
    case 'deferred':
      return 'Deferred Bug Reports';
  }
  return 'Title';
}


export const getBugTypeByAction = (action: string): string => {
  switch(action) {
    case 'new':
      return 'not_acknowledged';
    case 'unassigned':
      return 'not_assigned';
    case 'active':
      return 'in_process';
    case 'closed':
      return 'closed';
    case 'deferred':
      return 'deferred';
  }
  return 'Title';
}