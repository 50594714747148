import styled from 'styled-components/macro';

export const GridContainer = styled.div<{
  //   height?: string;
  //   width?: string;
  //   h_centered?: boolean;
  //   alignRight?: boolean;
  gridRow?: number;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.gridRow ?? '2'}, 1fr);
  gap: 16px;
  padding: 4px;
`;

export const GridItem = styled.div`
  display: flex;
`;
