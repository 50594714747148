import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext, UserEmpty, UserT } from '@contexts/UserContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import RoutesProtected from '@app_config/RoutesProtected';
import RoutesPublic from '@app_config/RoutesPublic';
import SideMenu from '@as_core/menus/SideMenu';
import LoadingMessage from '@as_core/account/LoadingMessage';

import AuthWrapper from '@as_core/account/AuthWrapper';

const queryClient = new QueryClient();

const debug: boolean = false;

const App = () => {
  const [isInitialLaunch] = useState<boolean>(true);
  const [isAppDataLoading, setIsAppDataLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserT | null>(UserEmpty);

  // Get the User Application Account Information -- after authenticated and registration loaded
  useEffect(() => {
    if (user.isRegistered && !user.isAppAuthorized && !isAppDataLoading) {
      setIsAppDataLoading(true);
      // TODO - Update for any application specific User information
      setUser((prev) => ({ ...prev, isAppAuthorized: true }));
      setIsAppDataLoading(false);
    }
  }, [user, isAppDataLoading]);

  debug && console.log('App | {user}', user);
  // when all API calls for user authentication/authorization are done
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AuthWrapper>
        <QueryClientProvider client={queryClient}>
          <Container>
            {isAppDataLoading ? (
              <LoadingMessage message={'Loading User Application Data'} />
            ) : user.isLoading && !isInitialLaunch ? (
              <LoadingMessage message={'Checking Authentication'} />
            ) : user.isAuthenticated && user.isRegistered ? (
              <>
                <SideMenu />
                <RoutesProtected />
              </>
            ) : (
              <RoutesPublic />
            )}
          </Container>
        </QueryClientProvider>
      </AuthWrapper>
    </UserContext.Provider>
  );
};
export default App;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: start;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
