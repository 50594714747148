import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';
import { CircularProgress } from '@mui/material';
import useBugReports from '@as_core/bugsReporting/useBugReports';
import { getTitle, getBugTypeByAction } from './utils';
import SystemSelector from '../shared/SystemSelector';
import { useQuery } from '@tanstack/react-query';
import DataLoading from '@as_core/elements/DataLoading';
import BugsTable from './components/BugsTable';
import {REQUIRED_ROLES} from './bugsAdminMenus';
import NotAuthorizedPage from '@subApps/shared/NotAuthorizedPage';

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

const debug = false;
const PgBugsAdmin = () => {
  const { action } = useParams();
  const [system, setSystem] = useState<string>('3RnD');
  const { user, setUser } = useContext(UserContext);
  const { getBugReportCounts, getBugReports } = useBugReports();

  debug && console.log('PgBugsAdmin | action:', action, ' user', user);

  if (!hasRequiredRole(user?.authRoles, REQUIRED_ROLES)) return <NotAuthorizedPage />;

  if (user.subApp !== 'bugs') {
    setUser((prev) => ({...prev, subApp: 'bugs', }));
  }

  // load the current counts
  const {
    isPending: isCountsPending,
    isError: isCountsError,
    error: countsError,
    data: bugCounts
  } = useQuery({
    queryKey: ['bugCounts', action],
    queryFn: () => getBugReportCounts(getBugTypeByAction(action)),
  })
  if (isCountsError) console.error(countsError.message);

  // load the current reports
  const {
    isPending: isReportsPending,
    isError: isReportsError,
    error: reportsError,
    data: bugReports
  } = useQuery({
    queryKey: ['bugReports', system, action],
    queryFn: () => getBugReports({
      admin: true,
      system: system,
      bug_type: getBugTypeByAction(action)
    }),
  })
  if (isReportsError) console.error(reportsError.message);

  if (debug) console.log('PgBugsAdmin | bugCounts:', bugCounts);

  return (
    <PageContainer title={getTitle(action)}>
      { isCountsPending ?
        <DataLoading /> :
        <SystemSelector
          key={action}
          systemCounts={bugCounts}
          system={system}
          setSystem={setSystem}
        />
      }
      <BugsListContainer>
        {isReportsPending ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <BugsList>
            {bugReports.length > 0 ? (
              <BugsTable
                action={action}
                system={system}
                bugReports={bugReports}
              />
            ) : (
              <BugsInfo>
                No new bugs - all bugs have been processed
              </BugsInfo>
            )}
          </BugsList>
        )}
      </BugsListContainer>
    </PageContainer>
  );
};

export default PgBugsAdmin;

const BugsListContainer = styled.div`
  width: 100%;
  margin-top: 5px;
`;

const BugsList = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

const BugsInfo = styled.div`
  text-align: center;
  width: 100%;
`;
