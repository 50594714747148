import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
import StationaryModal from '../elements/StationaryModal';
import DraggableModal from '../elements/DraggableModal';
import { Close } from '@mui/icons-material';
import BugsIcon from './bugs.icon';
import BugsSubmit from './bugs.submit';
import BugsList from './bugs.list';
import { BugsModalI, BugReportT } from './bugs.types';
import BugPreview from './bugPreview.modal';

import BugReportAddComment from './bug.comment';
import useBugReports from '@as_core/bugsReporting/useBugReports';

export const BugsModal = memo(function BugsModal(props: BugsModalI) {
  const { modalOpen, setModalOpen } = props;
  const { deleteBugReport } = useBugReports();
  const [isNewBugReported, setIsNewBugReported] = useState<boolean>(false); // used to refresh list
  const [inEditBug, setInEditBug] = useState<BugReportT | null>(null);
  const [deletedBugId, setDeletedBugId] = useState<string>();
  const [previewBug, setPreviewBug] = useState<BugReportT | null>();
  const [commentBugId, setCommentBugId] = useState<string | null>();

  const onActionBug = async (bug: BugReportT, type: 'delete' | 'edit') => {
    if (type === 'edit') {
      if (bug === inEditBug) {
        setInEditBug(null);
      } else {
        setInEditBug(bug);
      }
    } else if (type === 'delete') {
      try {
        await deleteBugReport(bug.uuid);
        setDeletedBugId(bug.uuid);
      } catch (err) {
        console.log('error: ', err);
      }
    } else if (type === 'view') {
      setPreviewBug(bug);
    } else if (type === 'comment') {
      setCommentBugId(bug.uuid);
      // console.log('comment: ', bug);
    }
  };

  return (
    <StationaryModal
      title={'Submit a Bug Report'}
      opacity={0.95}
      modalOpen={modalOpen}
      onCloseClick={() => setModalOpen(false)}
    >
      <InnerPanel>
        <ModalTitle>
          <ModalLabel>
            <StyledBugsIcon size={24} />
            <Title>Bugs Reporting</Title>
          </ModalLabel>
          <TitleIcon>
            <Close
              className='cursor-pointer'
              onClick={() => setModalOpen(false)}
            />
          </TitleIcon>
        </ModalTitle>
        <PanelBody>
          <PanelItem>
            <BugsSubmit
              setIsNewBugReported={setIsNewBugReported}
              inEditBug={inEditBug}
              setInEditBug={setInEditBug}
            />
          </PanelItem>
          <PanelItem>
            <BugsList
              onActionBug={onActionBug}
              inEditBug={inEditBug}
              isNewBugReported={isNewBugReported}
              deletedBugId={deletedBugId}
              setDeletedBugId={setDeletedBugId}
            />
          </PanelItem>
        </PanelBody>
      </InnerPanel>
      <StationaryModal
        title={'View Full Bug Report'}
        modalOpen={Boolean(previewBug)}
        onCloseClick={() => setPreviewBug(null)}
      >
        <BugPreview bug={previewBug} />
      </StationaryModal>
      <DraggableModal
        title={'Add Comment to Bug Report'}
        isModalOpen={!!commentBugId}
        onCloseClick={() => setCommentBugId(null)}
        height={240}
      >
        <BugReportAddComment
          uuid={commentBugId}
          closeDialog={() => setCommentBugId(null)}
        />
      </DraggableModal>
    </StationaryModal>
  );
});

const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 730px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 2px solid #636363;
  padding: 0;
  margin: 0;
`;

const ModalTitle = styled.div`
  align-items: center;
  position: relative;
  padding: 5px;
`;

const ModalLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  position: relative;
  padding: 5px;
`;

const StyledBugsIcon = styled(BugsIcon)`
  display: flex;
  position: relative;
  padding-right: 30px;
  padding-left: 5px;
`;

const Title = styled.div`
  display: flex;
  position: relative;
  font-size: ${(p) => p.theme.sizes.xlarge};
  max-width: 300px;
  margin-left: 10px;
`;

const TitleIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  filter: contrast(1) brightness(0.7);
  :hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    filter: contrast(1) brightness(1);
  }
`;

const PanelBody = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  min-height: 300px;
`;

const PanelItem = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 5px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;
