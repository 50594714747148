import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import BugsNewIcon from '@as_core/icons/bugsNew.icon';
import BugsUnassignedIcon from '@as_core/icons/bugsUnassigned.icon';
import BugsActiveIcon from '@as_core/icons/bugsActive.icon';
import BugsClosedIcon from '@as_core/icons/bugsClosed.icon';
import BugsDeferredIcon from '@as_core/icons/bugsDeferred.icon';
import { UserT } from '@contexts/UserContext';
import { hasRequiredRole } from '@subApps/bugs/PgBugsAdmin';

export const REQUIRED_ROLES = ['aseda-admin', 'aseda-dev'];

export const ordersMenuItems: MenuItemsT[] = [
  {
    id: 'brNew',
    name: 'New - Unacknowledged',
    pathname: '/bugs/new',
    icon: <BugsNewIcon />,
  },
  {
    id: 'brUnassigned',
    name: 'Unassigned',
    pathname: '/bugs/unassigned',
    icon: <BugsUnassignedIcon />,
  },
  {
    id: 'brActive',
    name: 'Active',
    pathname: '/bugs/active',
    icon: <BugsActiveIcon />,
  },
  {
    id: 'brClosed',
    name: 'Closed',
    pathname: '/bugs/closed',
    icon: <BugsClosedIcon />,
  },
  {
    id: 'brDeferred',
    name: 'Deferred',
    pathname: '/bugs/deferred',
    icon: <BugsDeferredIcon />,
  },

];

const debug = false;
export const getBugsAdminMenus = (user: UserT) => {
  if (debug) console.log('getBugsAdminMenus | user', user);
  if (!hasRequiredRole(user?.authRoles, REQUIRED_ROLES)) return [];
  return ordersMenuItems;
}


