import React, { useState } from 'react';
import { UpdateBugReportT } from '@as_core/bugsReporting/bugs.types';
import {
  BasicTextInput,
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import TextButton from '@as_core/controls/buttons/TextButton';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import { Checkbox } from '@mui/material';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';

type PropsT = {
  handleUpdate?: (updates: UpdateBugReportT) => void;
  handleCancel?: () => void;
}

const debug = false;
export const AssignDeveloper = (props:PropsT) => {
  const { handleUpdate} = props;
  const [updates, setUpdates] = useState({});

  const _handleUpdates = (field:string, value:string) => {
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const _handleCheckboxUpdates = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug && console.log(event.target.checked);
    const state = event.target.checked ? 'true' : 'false';
    setUpdates((prev) => ({...prev, 'resolution.deferred': state }));
  }


  const handleCancel = () => {
    props?.handleCancel && props.handleCancel();
  }

  const disabled = Object.keys(updates).length !== 1;

  return (
    <Container key={'assigned-bug-report-form'}>
        <Row width='375px' align={'flex-start'}>
          <FormLabel width={120}>Assign Developer:</FormLabel>
          <FlexItem width={'275px'}>
            <BasicTextInput
              id='assign'
              type='string'
              name='developer'
              placeholder='enter developer email ...'
              value={Object.hasOwn(updates, 'resolution.assigned') ? updates['resolution.assigned'] : ''}
              onChange={(e) => _handleUpdates('resolution.assigned', e.target.value)}
            />
          </FlexItem>
        </Row>
        <Row width='300px' align={'flex-start'}>
          <Checkbox
            checked={updates['resolution.deferred'] === 'true'}
            onChange={_handleCheckboxUpdates}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            size='small'
          />
          <FormLabel>Defer Bug Report for Future Consideration
            <HoverInfoText text={'This is not a critical bug, but a feature request. It will go to deferred bucket, but not be lost.'}/>
          </FormLabel>
        </Row>
        <ActionButtons>
          <TextButton width={100} label={'Cancel'} onClick={() => handleCancel()} />
          <TextButton
            width={100}
            label={'Save Changes'}
            isDisabled={disabled}
            onClick={disabled ? ()=>{} : () => handleUpdate(updates)}
          />
        </ActionButtons>
    </Container>
  );
};

export default AssignDeveloper;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;