import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { FlexColumn, FlexItem } from '@as_core/elements/flexStyles';
import DraggableModal from '@components/elements/DraggableModal';
import CreateCoupon from '@subApps/coupons/forms/CreateCoupon';
import BasicTable from '@as_core/tables/BasicTable';
import { ColDef, getCouponTableRows } from '../utils';
import { CouponCreateT } from '@subApps/coupons/coupons.types';
import useCoupons from '@subApps/coupons/useCoupons';
import { CircularProgress } from '@mui/material';
import UpdateExpiration from '@subApps/coupons/forms/UpdateExpiration';


const debug = false;
const CouponsTable = (props) => {
  const { coupons } = props;
  const [couponRows, setCouponRows] = useState<any[]>([]);
  const [updatingCoupons, setUpdatingCoupons] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const { createCoupon, updateCoupon, deleteCoupon } = useCoupons();
  debug && console.log('CouponsTable | selectedCoupon:', selectedCoupon);

  const handleModalClose = () => setIsModalOpen(false);

  const handleClick = (type: string, uuid: string) => {
    debug && console.log('handleClick ', type, uuid);
    const matched = coupons.filter((r) => r.id === uuid);
    if (matched.length) {
      setSelectedCoupon(matched[0]);
      debug && console.log('handleEditClick: matched', matched);
      switch(type) {
        case 'edit':
          setIsModalOpen(true);
          break;
        case 'delete':
          handleDelete(uuid);
          break;
      }
    }
  };

  const handleUpdateExpiration = (expires: any) => {
    const updatedExpires = new Date(expires).toISOString();
    debug && console.log('handleUpdateExpiration ', selectedCoupon?.id, updatedExpires);
    setUpdatingCoupons(true);
    setCouponRows([]);
    updateCoupon({'id': selectedCoupon?.id, 'expires': updatedExpires})
      .then((res) => {
          debug && console.log(res);
          setUpdatingCoupons(false);
        }
      )
      .catch();
  }

  const handleDelete = (id: string) => {
    debug && console.log('handleDelete ', id);
    setUpdatingCoupons(true);
    setCouponRows([]);
    deleteCoupon(id)
      .then((res) => {
          debug && console.log(res);
          setUpdatingCoupons(false);
        }
      )
      .catch();
  }

  const handleCreate = (newCoupon:CouponCreateT) => {
    debug && console.log('handleCreate ', newCoupon);
    setUpdatingCoupons(true);
    setIsCreateModalOpen(false);
    setCouponRows([]);
    newCoupon['expires'] = new Date(newCoupon['expires']).toISOString();
    console.log(newCoupon['expires']);
    createCoupon(newCoupon)
      .then((res) => {
        console.log(res);
        setUpdatingCoupons(false);
      })
      .catch();
  };

  useEffect(() => {
    setCouponRows(getCouponTableRows(coupons, handleClick))
  }, [coupons]);

  if (!coupons || coupons.length === 0) {
    return <></>;
  }

  debug && console.log('CouponsTable: {coupons}', coupons);
  debug && console.log('CouponsTable: {couponRows}', couponRows);
  debug && console.log('CouponsTable: {cols}', ColDef);

  return (
    <CouponsTableContainer>
      { updatingCoupons ?
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      :
        <>
          <Card
            onClick={()=>setIsCreateModalOpen(true)}
          >
            Create a new Coupon
          </Card>
          <FlexColumn h_centered width={'100%'}>
            <FlexItem>
              <BasicTable
                fields={ColDef}
                rows={couponRows}
              />
            </FlexItem>
          </FlexColumn>
        </>
      }
      <DraggableModal
        title={'Update Coupon Expiration'}
        height={150}
        width={500}
        isModalOpen={isModalOpen}
        onCloseClick={handleModalClose}
      >
        <UpdateExpiration
          expires={selectedCoupon?.expires}
          handleUpdate={handleUpdateExpiration}
          handleCancel={()=>setIsModalOpen(false)}
        />
      </DraggableModal>
      <DraggableModal
        title={'Create New Coupon'}
        isModalOpen={isCreateModalOpen}
        onCloseClick={() => setIsCreateModalOpen(false)}
      >
        <CreateCoupon
          handleCreate={handleCreate}
          handleCancel={()=>setIsCreateModalOpen(false)}
        />
      </DraggableModal>
    </CouponsTableContainer>
  );
};

export default CouponsTable;

const CouponsTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;


const Card = styled.span`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px;
  padding: 10px;
  font-size: 14px;
  min-width: 200px;
  cursor: pointer;
  color: ${(p) => p.theme.palette.textSecondary};
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
  background: ${(p) => p.theme.palette.backgroundTertiary};
  :hover {
    color: ${(p) => p.theme.palette.textPrimary};
    background: ${(p) => p.theme.palette.accentPrimary};
  }
`;
