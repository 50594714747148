import AddComment from '@subApps/bugs/forms/AddComment';
import useBugReports from '@as_core/bugsReporting/useBugReports';

const debug = false;
const BugReportAddComment = (props) => {
  let { uuid } = props;
  const { addBugReportComment } = useBugReports();

  if (debug) console.log('BugReportAddComment | uuid:', uuid);

  const _handleUpdate = async (comment:string) => {
    if (debug) console.log('UpdateBugReport | _handleUpdate', uuid, comment);
    await addBugReportComment(uuid, comment);
    props?.closeDialog && props.closeDialog();
  }

  return (
    <AddComment
      handleUpdate={_handleUpdate}
      handleCancel={props.handleCancel}
    />
  );
};

export default BugReportAddComment;