import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import { UserContext } from '@contexts/UserContext';
import { CircularProgress } from '@mui/material';
import useCoupons from './useCoupons';
import CouponsTable from './components/CouponsTable';
import NotAuthorizedPage from '@subApps/shared/NotAuthorizedPage';
import { useQuery } from '@tanstack/react-query';

const REQUIRED_ROLES = ['aseda-admin'];

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

const debug = false;
const PgBugsAdmin = () => {
  const { action} = useParams();
  const { user, setUser } = useContext(UserContext);
  const { getAllCoupons } = useCoupons();
  debug && console.log('PgCouponsAdmin | action:', action, ' user', user);

  if (!hasRequiredRole(user?.authRoles, REQUIRED_ROLES)) return <NotAuthorizedPage />;

  if (user.subApp !== 'coupons') { setUser((prev) => ({ ...prev, subApp: 'coupons' }));}

  // load the current counts
  const { isPending, isError, error, data: coupons } = useQuery({
    queryKey: ['coupons', 'all'],
    queryFn: () => getAllCoupons(),
  })
  if (isError) console.error(error.message);

  return (
    <PageContainer title={'Coupon Administration'}>
      <Container>
        <CouponsListContainer>
          {isPending ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <CouponsList>
              <CouponsTable
                coupons={coupons}
              />
            </CouponsList>
          )}
        </CouponsListContainer>
      </Container>
    </PageContainer>
  );
};

export default PgBugsAdmin;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  margin-top: 24px;
`;

const CouponsListContainer = styled.div`
  width: 100%;
`;

const CouponsList = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;
