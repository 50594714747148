import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Checkbox } from '@mui/material';

interface PropsI {
  field: string;
  label: string;
  value: boolean;
  onChange: (boolean) => void;
}

const InputBillingCheck = (props: PropsI) => {
  const [checked, setChecked] = useState<boolean>(props?.value);

  const handleChange = () => {
    props.onChange(!checked);
    setChecked((prev) => !prev);
  };

  return (
    <BillingFieldContainer key={'address_billing'}>
      <StyledCheckBox
        key={props?.value + '_' + props?.label}
        checked={props.value}
        size={'small'}
        onChange={handleChange}
      />
      <Label checked={props.value}>{props.label}</Label>
    </BillingFieldContainer>
  );
};

export default InputBillingCheck;

const BillingFieldContainer = styled.div`
  height: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCheckBox = styled(Checkbox)`
  font-size: ${(p) => p.theme.sizes.xsmall};
  display: flex;
`;

const Label = styled.div<{ checked: boolean }>`
  font-size: ${(p) => p.theme.sizes.xsmall};

  color: ${(p) =>
    p.checked ? p.theme.palette.accentPrimary : p.theme.palette.textSecondary};
`;
